import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import HomeLoad from './HomeLoad';

const Root = React.lazy( () => 
  import( "./Root")
);

ReactDOM.render(
  <React.Suspense fallback={<HomeLoad />}>
  <Root />
  </React.Suspense>
  ,
  document.getElementById("root")
);


//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
